<!--
 * @Author: Akarichan
 * @LastEditors: Akarichan
 * @Date: 2021-09-23 21:59:40
 * @LastEditTime: 2021-09-29 23:22:23
-->
<template>
    <div>
        <div class="home__main">
            <h1>🐎UMUI</h1>
            <h2>赛马娘风格Vue组件库</h2>
            <h3><a href="https://github.com/TachibanaKimika/umamusume-ui">github仓库</a></h3>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    }
}
</script>

<style lang="scss">
.home__main {
    color: #593b00;
}

.home__main {
    text-align: center;
}
</style>